// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bowling-js": () => import("./../../../src/pages/bowling.js" /* webpackChunkName: "component---src-pages-bowling-js" */),
  "component---src-pages-hacker-news-index-tsx": () => import("./../../../src/pages/hacker-news/index.tsx" /* webpackChunkName: "component---src-pages-hacker-news-index-tsx" */),
  "component---src-pages-hacker-news-settings-tsx": () => import("./../../../src/pages/hacker-news/settings.tsx" /* webpackChunkName: "component---src-pages-hacker-news-settings-tsx" */),
  "component---src-pages-index-blog-js": () => import("./../../../src/pages/index-blog.js" /* webpackChunkName: "component---src-pages-index-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-project-js": () => import("./../../../src/pages/index-project.js" /* webpackChunkName: "component---src-pages-index-project-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-project-calculator-js": () => import("./../../../src/pages/project-calculator.js" /* webpackChunkName: "component---src-pages-project-calculator-js" */),
  "component---src-pages-project-resume-js": () => import("./../../../src/pages/project-resume.js" /* webpackChunkName: "component---src-pages-project-resume-js" */),
  "component---src-pages-resume-about-js": () => import("./../../../src/pages/resume/about.js" /* webpackChunkName: "component---src-pages-resume-about-js" */),
  "component---src-pages-resume-bio-js": () => import("./../../../src/pages/resume/bio.js" /* webpackChunkName: "component---src-pages-resume-bio-js" */),
  "component---src-pages-resume-employmenthistory-js": () => import("./../../../src/pages/resume/employmenthistory.js" /* webpackChunkName: "component---src-pages-resume-employmenthistory-js" */),
  "component---src-pages-resume-food-js": () => import("./../../../src/pages/resume/food.js" /* webpackChunkName: "component---src-pages-resume-food-js" */),
  "component---src-pages-resume-index-js": () => import("./../../../src/pages/resume/index.js" /* webpackChunkName: "component---src-pages-resume-index-js" */),
  "component---src-pages-resume-music-js": () => import("./../../../src/pages/resume/music.js" /* webpackChunkName: "component---src-pages-resume-music-js" */),
  "component---src-templates-template-blog-post-js": () => import("./../../../src/templates/template-blog-post.js" /* webpackChunkName: "component---src-templates-template-blog-post-js" */),
  "component---src-templates-template-docs-markdown-js": () => import("./../../../src/templates/template-docs-markdown.js" /* webpackChunkName: "component---src-templates-template-docs-markdown-js" */)
}

